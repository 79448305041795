import { React, useEffect, useState, useRef } from "react";
import { Input, Button, Card, Table } from "@douyinfe/semi-ui";
import { IconDownload, IconEyeOpened } from "@douyinfe/semi-icons";
import requests from "../../../../utils/request_noloading";
import { download_file, get_file_url } from "../../../../utils/file";

const columns = [
  {
    title: "文件名称",
    dataIndex: "name",
  },
  {
    title: "操作",
    dataIndex: "operate",
    render: (text, record) => <Operation text={text} record={record} />,
  },
];

export default function YuewenAppFile() {
  const [keyword, set_keyword] = useState("");
  const [data, set_data] = useState([]);
  const [load, set_load] = useState(false);
  const handle_search = () => {
    set_load(true);
    requests
      .post("/file/search_file", {
        type: "shouwen",
        keyword: keyword,
      })
      .then((res) => {
        set_data(res.data);
      })
      .finally(() => {
        set_load(false);
      });
  };
  return (
    <>
      <Input
        placeholder="输入关键字搜索"
        size="large"
        style={{ float: "left", width: "75%" }}
        onChange={(val) => set_keyword(val)}
      />
      <Button
        theme="solid"
        size="large"
        onClick={handle_search}
        style={{ float: "left", width: "20%", marginLeft: "5%" }}
      >
        搜 索
      </Button>
      <br />
      <br />
      <br />
      <Table
        empty={"暂无数据"}
        columns={columns}
        dataSource={data}
        rowKey="file_id"
        pagination={false}
        bordered={true}
        loading={load}
      />
    </>
  );
}

function Operation({ text, record }) {
  const handlePreview = async () => {
    const url = (await get_file_url(record)) + "&fullfilename=" + record.name;
    console.log(url);
    window.open(
      process.env.REACT_APP_FILE_PREVIEW_URL +
        "/onlinePreview?url=" +
        btoa(encodeURIComponent(url))
    );
  };
  return (
    <>
      <Button
        icon={<IconDownload />}
        size="small"
        theme="solid"
        style={{ marginRight: 10, marginBottom: 5 }}
        onClick={() => {
          download_file(record);
        }}
      >
        下载
      </Button>
      <Button
        icon={<IconEyeOpened />}
        size="small"
        theme="solid"
        style={{ marginRight: 10 }}
        onClick={handlePreview}
      >
        预览
      </Button>
    </>
  );
}
