import { React, useEffect, useState, useRef } from "react";
import {
  Space,
  Descriptions,
  Toast,
  Row,
  Button,
  TextArea,
  Col,
  Form,
  Typography,
  Image,
  Modal,
} from "@douyinfe/semi-ui";
import requests from "../../../../utils/request_noloading";
import FileList from "../../../public/file/pages/file_list";
import { convertUrlParamsToJson } from "../../../../utils/location_util";
import {
  userA_bz,
  userB_bz,
  renderTagItem,
  userG_bz,
  hyjpx,
} from "../houduan/js/shouwen_upload";
import DingUserChose from "../../../../component/ding_user_chose";
import { useNavigate } from "react-router";
import { download_file } from "../../../../utils/file";
import YuewenLiuchengShow from "./yuewen_liucheng_show";
import CondShowComp from "../../../../component/cond_show_comp";
import { change_route } from "../../../../router";

// 领导批示
let ldps = null;

// 需要新增用户的表单字段名称
let field_name = null;

const userC_bz = "分管领导";
const userD_bz = "分管领导阅示后处理人";
const userE_bz = "执行科室";
export const userF_bz = "抄送人";

// 字体大小
const title_size = 6;
const content_size = 4;

// 分管领导(默认值)
const C_init = [
  {
    username: "宋美英",
    userid: "243030602823566830",
    bz: userC_bz,
  },
  {
    username: "蒋君",
    userid: "14622611321073392",
    bz: userC_bz,
  },
  {
    username: "周羽",
    userid: "1011360849702549",
    bz: userC_bz,
  },
  {
    username: "张漪",
    userid: "2161054159783370",
    bz: userC_bz,
  },
  {
    username: "赵战良",
    userid: "182859220635612556",
    bz: userC_bz,
  },
];

// 阅示完毕后处理人(默认值)
const D_init = [
  {
    username: "谭佳宁",
    userid: "01313100151735139419",
    bz: userD_bz,
  },
  {
    username: "张婷",
    userid: "2431205228778071",
    bz: userD_bz,
  },
  {
    username: "贺靓",
    userid: "20120161141159513",
    bz: userD_bz,
  },
];

// 抄送人
const F_init = [
  {
    username: "谭佳宁",
    userid: "01313100151735139419",
    bz: userF_bz,
  },
  {
    username: "张婷",
    userid: "2431205228778071",
    bz: userF_bz,
  },
  {
    username: "贺靓",
    userid: "20120161141159513",
    bz: userF_bz,
  },
  {
    username: "宋美英",
    userid: "243030602823566830",
    bz: userF_bz,
  },
  {
    username: "蒋君",
    userid: "14622611321073392",
    bz: userF_bz,
  },
  {
    username: "周羽",
    userid: "1011360849702549",
    bz: userF_bz,
  },
  {
    username: "张漪",
    userid: "2161054159783370",
    bz: userF_bz,
  },
  {
    username: "赵战良",
    userid: "182859220635612556",
    bz: userF_bz,
  },
];

// 抄送人2
const F_init2 = [
  {
    username: "谭佳宁",
    userid: "01313100151735139419",
    bz: userF_bz,
  },
  {
    username: "张婷",
    userid: "2431205228778071",
    bz: userF_bz,
  },
  {
    username: "贺靓",
    userid: "20120161141159513",
    bz: userF_bz,
  },
];

// 领导批示输入框标题获取
const get_ldps_title = (bz) => {
  switch (bz) {
    case userA_bz:
      return "领导批示";
    case userC_bz:
      return "分管领导意见";
    case userE_bz:
      return "科室负责人意见";
    default:
      return "领导批示";
  }
};

export default function ShouWenYueshi() {
  const route = useNavigate();
  const [sw_data, set_sw_data] = useState({});
  const [yuewen_user_data, set_yuewen_user_data] = useState({});
  // 领导批示
  const [ldps_data, set_ldps_data] = useState("");
  // 分管领导意见
  const [fgld_data, set_fgld_data] = useState("");
  // 责任科室意见
  const [zrks_data, set_zrks_data] = useState("");
  const [load, set_load] = useState(false);
  const formApi = useRef();
  const [user_chose_v, set_user_chose_v] = useState(false);
  // 当前选择框的备注
  const [cur_bz, set_cur_bz] = useState(null);
  const sw_id = convertUrlParamsToJson(window.location.search)["sw_id"];
  // 当前用户所在顺序
  const order = convertUrlParamsToJson(window.location.search)["order"];
  // 用于刷新某些组件
  const [rerefsh_flag, set_refresh_flag] = useState(false);
  useEffect(() => {
    if (sw_id) {
      // 获取收文数据
      requests
        .get("/office/get_shouwen_data_by_id", {
          params: { sw_id: sw_id },
        })
        .then((res) => {
          if (!res.data) {
            route("/office/shouwen_yueshi_chehui");
            return;
          }
          set_sw_data(res.data);
        });
      // 获取当前用户在流程中的数据
      requests
        .get("/office/get_shouwen_liucheng_by_userid", {
          params: { sw_id: sw_id, order: order },
        })
        .then((res) => {
          if (!res.data) {
            route("/office/shouwen_yueshi_chehui");
            return;
          }
          // 如果当前用户处于抄送人或院领导流程则在页面加载时自动设置为已处理
          if ([userF_bz, userG_bz].includes(res.data.bz)) {
            const up_data = {
              sw_id: sw_id,
              order: order,
              yueshi_liucheng_id: res.data.yueshi_liucheng_id,
            };
            requests
              .post("/office/set_yi_yue", up_data)
              .then((res) => {
                if (res.code === 200) {
                  // 第一次设置为已处理的操作
                  set_refresh_flag(!rerefsh_flag);
                } else {
                  Toast.warning({ content: res.msg, duration: 5 });
                }
              })
              .finally(() => {});
          }
          set_yuewen_user_data(res.data);
        });
      // 获取UserA领导批示
      requests
        .get("/office/get_liucheng_ldps", {
          params: { sw_id: sw_id, bz: userA_bz },
        })
        .then((res) => {
          if (res.data) {
            let str = "";
            for (let i in res.data) {
              str += res.data[i].ldps + ";";
            }
            set_ldps_data(str);
          }
        });
      // 获取UserC领导批示
      requests
        .get("/office/get_liucheng_ldps", {
          params: { sw_id: sw_id, bz: userC_bz },
        })
        .then((res) => {
          if (res.data) {
            let str = "";
            for (let i in res.data) {
              str += res.data[i].ldps + ";";
            }
            set_fgld_data(str);
          }
        });
      // 获取UserE领导批示
      requests
        .get("/office/get_liucheng_ldps", {
          params: { sw_id: sw_id, bz: userE_bz },
        })
        .then((res) => {
          if (res.data) {
            let str = "";
            for (let i in res.data) {
              str += res.data[i].ldps + ";";
            }
            set_zrks_data(str);
          }
        });
    }
  }, []);

  // 弹框提示是否处理下一个流程
  const next_liucheng_tip = () => {
    requests
      .post("/office/search_yuewen_full_info", {
        is_look: false,
        currentPage: 1,
        pageSize: 1,
        is_send: true,
      })
      .then((res) => {
        if (res.data.result && res.data.result.length > 0) {
          Modal.success({
            title: "操作成功",
            content: `提交成功！（还有${res.data.total}个流程未处理）`,
            hasCancel: true,
            centered: true,
            okText: "处理下一流程",
            onOk: () => {
              const data = res.data.result[0];
              window.location.href = `/office/shouwen_yueshi?sw_id=${data.sw_id}&order=${data.order_num}`;
              // route(`/office/shouwen_yueshi?sw_id=${data.sw_id}&order=${data.order_num}`)
            },
            footerFill: true,
          });
        } else {
          Modal.success({
            title: "操作成功",
            content: "恭喜，所有流程已处理完毕🥳！",
            hasCancel: false,
            centered: true,
            onOk: () => window.location.reload(),
            footerFill: true,
          });
        }
      });
    // route(`/office/yuewen_liucheng_show?sw_id=${sw_id}`);
  };

  const handle_yi_yue = () => {
    set_load(true);
    const up_data = {
      sw_id: sw_id,
      order: order,
      yueshi_liucheng_id: yuewen_user_data.yueshi_liucheng_id,
    };
    // 数据处理
    if (
      [userA_bz, userC_bz, userE_bz, userG_bz].includes(yuewen_user_data.bz)
    ) {
      up_data["ldps"] = ldps;
    }
    if ([userB_bz, userD_bz].includes(yuewen_user_data.bz)) {
      const l = [];
      l.push(formApi.current.getValue("User_C"));
      l.push(formApi.current.getValue("User_D"));
      up_data["users"] = l;
    }
    // 设置用户已阅
    requests
      .post("/office/set_yi_yue", up_data)
      .then((res) => {
        if (res.code === 200) {
          next_liucheng_tip();
        } else {
          Toast.warning({ content: res.msg, duration: 5 });
        }
      })
      .finally(() => {
        set_load(false);
      });
  };

  const handleLdpsChange = (val) => {
    ldps = val;
  };

  // 点击选择按钮调用
  const handleSelectUser = (f_name, bz) => {
    field_name = f_name;
    set_cur_bz(bz);
    set_user_chose_v(true);
  };

  // 选择好用户后的回调
  const onSelectUser = (users) => {
    users = users.map((user) => {
      user.bz = cur_bz;
      return user;
    });
    let new_users = formApi.current.getValue(field_name);
    new_users = new_users.concat(users);
    // 去重，非必要功能，暂时不做

    formApi.current.setValue(field_name, new_users);
  };

  // 导出收文处理单
  const handle_export = () => {
    set_load(true);
    const d = sw_data;
    d["fgld_data"] = fgld_data;
    d["zrks_data"] = zrks_data;
    requests
      .post("/office/shouwen_model", d)
      .then((res) => {
        download_file({ path: res.data });
      })
      .finally(() => {
        set_load(false);
      });
  };

  const { TagInput } = Form;
  const { Text, Title } = Typography;

  return (
    <>
      <DingUserChose
        visible={user_chose_v}
        setvisible={set_user_chose_v}
        onSelect={onSelectUser}
      />
      <div style={{ margin: 20 }}>
        <Row>
          <center>
            <Image width={200} height={50} src="/hospital.png" />
          </center>
          <hr />
          <Row>
            <Col span={24}>
              <div className="col-content">
                {["行政发文", "中共党委发文", hyjpx, "传阅"].includes(
                  sw_data.sw_type
                ) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      文件类型
                    </Title>
                    <Title heading={content_size}>{sw_data["sw_type"]}</Title>
                    <br />
                  </>
                ) : null}

                {["行政发文", "中共党委发文", hyjpx].includes(
                  sw_data.sw_type
                ) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      来文单位
                    </Title>
                    <Title heading={content_size}>{sw_data["fwdw"]}</Title>
                    <br />
                  </>
                ) : null}

                {["行政发文", "中共党委发文"].includes(sw_data.sw_type) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      原文编号
                    </Title>
                    <Title heading={content_size}>{sw_data["ywbh"]}</Title>
                    <br />
                  </>
                ) : null}

                {["行政发文", "中共党委发文"].includes(sw_data.sw_type) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      收文途径
                    </Title>
                    <Title heading={content_size}>{sw_data["swtj"]}</Title>
                    <br />
                  </>
                ) : null}

                {["行政发文", "中共党委发文", hyjpx].includes(
                  sw_data.sw_type
                ) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      发文时间
                    </Title>
                    <Title heading={content_size}>{sw_data["fwsj"]}</Title>
                    <br />
                  </>
                ) : null}

                {[hyjpx].includes(sw_data.sw_type) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      会议名称
                    </Title>
                    <Title heading={content_size}>{sw_data["hymc"]}</Title>
                    <br />
                  </>
                ) : null}

                {["行政发文", "中共党委发文"].includes(sw_data.sw_type) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      收文时间
                    </Title>
                    <Title heading={content_size}>{sw_data["swsj"]}</Title>
                    <br />
                  </>
                ) : null}

                {["行政发文", "中共党委发文", "传阅"].includes(
                  sw_data.sw_type
                ) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      原文标题
                    </Title>
                    <Title heading={content_size}>{sw_data["ywbt"]}</Title>
                    <br />
                  </>
                ) : null}

                {["行政发文", "中共党委发文"].includes(sw_data.sw_type) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      拟存档文限
                    </Title>
                    <Title heading={content_size}>{sw_data["ncdwx"]}</Title>
                    <br />
                  </>
                ) : null}

                {["行政发文", "中共党委发文"].includes(sw_data.sw_type) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      类别
                    </Title>
                    <Title heading={content_size}>{sw_data["type"]}</Title>
                    <br />
                  </>
                ) : null}

                {["行政发文", "中共党委发文"].includes(sw_data.sw_type) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      所在部门
                    </Title>
                    <Title heading={content_size}>{sw_data["szbm"]}</Title>
                    <br />
                  </>
                ) : null}

                {/* <br />
                <Title heading={title_size} type="tertiary">
                  处理意见
                </Title>
                <Title heading={content_size}>{sw_data["clyj"]}</Title> */}

                {["行政发文", "中共党委发文", hyjpx].includes(
                  sw_data.sw_type
                ) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      备注
                    </Title>
                    <Title heading={content_size}>{sw_data["bz"]}</Title>
                    <br />
                  </>
                ) : null}

                {["行政发文", "中共党委发文"].includes(sw_data.sw_type) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      批阅范围
                    </Title>
                    <Title heading={content_size}>{sw_data["pyfw"]}</Title>
                    <br />
                  </>
                ) : null}

                {["行政发文", "中共党委发文"].includes(sw_data.sw_type) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      领导批示
                    </Title>
                    <Title heading={content_size}>{ldps_data}</Title>
                    <br />
                  </>
                ) : null}

                {["行政发文", "中共党委发文"].includes(sw_data.sw_type) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      分管领导意见
                    </Title>
                    <Title heading={content_size}>{fgld_data}</Title>
                    <br />
                  </>
                ) : null}

                {["行政发文", "中共党委发文"].includes(sw_data.sw_type) ? (
                  <>
                    <Title heading={title_size} type="tertiary">
                      责任科室意见
                    </Title>
                    <Title heading={content_size}>{zrks_data}</Title>
                    <br />
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
        </Row>
        <hr />

        <Row>
          <h4>附件查看</h4>
          <FileList item_id={sw_id} type="shouwen" show_download={true} />
        </Row>

        <CondShowComp
          // 用户没提交过时显示
          cond_func={() => !yuewen_user_data.is_look}
          comp={
            <CondShowComp
              // 符合条件的用户会显示领导批示输入框
              cond_func={() =>
                [userA_bz, userC_bz, userE_bz].includes(yuewen_user_data.bz) ||
                (yuewen_user_data.bz === userG_bz && sw_data.sw_type === hyjpx)
              }
              comp={
                <Row>
                  <h4>{get_ldps_title(yuewen_user_data.bz)}</h4>
                  <TextArea
                    rows={5}
                    placeholder="在此输入内容..."
                    onChange={handleLdpsChange}
                  />
                </Row>
              }
            />
          }
        />
        {/* 用户没提交时才显示 */}
        {!yuewen_user_data.is_look ? (
          <Form
            style={{ padding: 10, width: "90%" }}
            getFormApi={(fApi) => {
              formApi.current = fApi;
            }}
          >
            {({ formState }) => (
              <>
                {yuewen_user_data.bz === userB_bz &&
                sw_data.sw_type !== hyjpx ? (
                  <>
                    <TagInput
                      field="User_C"
                      allowDuplicates={false}
                      label="分管领导"
                      initValue={C_init}
                      style={{ width: "90%" }}
                      renderTagItem={(value, index, onClose) =>
                        renderTagItem(value, index, onClose)
                      }
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        handleSelectUser("User_C", userC_bz);
                      }}
                    >
                      新增
                    </Button>
                  </>
                ) : null}

                {yuewen_user_data.bz === userB_bz &&
                sw_data.sw_type !== hyjpx ? (
                  <>
                    <TagInput
                      field="User_D"
                      label="分管领导阅示完毕后处理人"
                      initValue={D_init}
                      style={{ width: "90%" }}
                      renderTagItem={(value, index, onClose) =>
                        renderTagItem(value, index, onClose)
                      }
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        handleSelectUser("User_D", userD_bz);
                      }}
                    >
                      新增
                    </Button>
                  </>
                ) : null}

                {yuewen_user_data.bz === userD_bz ||
                (yuewen_user_data.bz === userB_bz &&
                  sw_data.sw_type === hyjpx) ? (
                  <>
                    <TagInput
                      field="User_C"
                      allowDuplicates={false}
                      label={userE_bz}
                      initValue={[]}
                      style={{ width: "90%" }}
                      renderTagItem={(value, index, onClose) =>
                        renderTagItem(value, index, onClose)
                      }
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        handleSelectUser("User_C", userE_bz);
                      }}
                    >
                      新增
                    </Button>
                  </>
                ) : null}

                {yuewen_user_data.bz === userD_bz ? (
                  <>
                    <Form.TagInput
                      field="User_D"
                      label={userF_bz}
                      initValue={F_init}
                      style={{ width: "90%" }}
                      renderTagItem={(value, index, onClose) =>
                        renderTagItem(value, index, onClose)
                      }
                    />
                    <Button
                      type="primary"
                      style={{ marginTop: 12 }}
                      onClick={() => {
                        handleSelectUser("User_D", userF_bz);
                      }}
                    >
                      新增
                    </Button>
                  </>
                ) : null}

                {yuewen_user_data.bz === userB_bz &&
                sw_data.sw_type === hyjpx ? (
                  <>
                    <Form.TagInput
                      field="User_D"
                      label={userF_bz}
                      initValue={F_init2}
                      style={{ width: "90%" }}
                      renderTagItem={(value, index, onClose) =>
                        renderTagItem(value, index, onClose)
                      }
                    />
                    <Button
                      type="primary"
                      style={{ marginTop: 12 }}
                      onClick={() => {
                        handleSelectUser("User_D", userF_bz);
                      }}
                    >
                      新增
                    </Button>
                  </>
                ) : null}
              </>
            )}
          </Form>
        ) : null}

        <Row>
          <h4>流程</h4>
          <YuewenLiuchengShow
            sw_id={sw_id}
            yl_id={yuewen_user_data.yueshi_liucheng_id}
            refresh_flag={rerefsh_flag}
          />
        </Row>

        {!yuewen_user_data.is_look &&
        ![userF_bz, userG_bz].includes(yuewen_user_data.bz) ? (
          <Row>
            <Button
              style={{ marginBottom: 20, marginTop: 30 }}
              size="large"
              theme="solid"
              loading={load}
              onClick={handle_yi_yue}
              block
            >
              提 交
            </Button>
          </Row>
        ) : null}

        {[userF_bz, userG_bz].includes(yuewen_user_data.bz) ? (
          <Row>
            <Button
              style={{ marginBottom: 20, marginTop: 30 }}
              size="large"
              onClick={next_liucheng_tip}
              block
            >
              查看下一流程
            </Button>
          </Row>
        ) : null}
        
        {yuewen_user_data.bz === userF_bz ? (
          <>
            <Row>
              <Button
                style={{ marginBottom: 20 }}
                size="large"
                theme="solid"
                loading={load}
                onClick={handle_export}
                block
              >
                导 出
              </Button>
            </Row>
          </>
        ) : null}
      </div>
    </>
  );
}
