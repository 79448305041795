import { useEffect, useState } from "react";
import { useQRCode } from "next-qrcode";
import { Button, Spin, Typography } from "@douyinfe/semi-ui";
import { IconRefresh, IconTickCircle, IconPhone } from "@douyinfe/semi-icons";
import requests from "../../../../utils/request_noloading";

// 自己开发的扫码登录
export default function QRCodeLogin2({ handleSubmit }) {
  const { Canvas } = useQRCode();
  // 控制请求是否取消
  const [controller, setController] = useState(new AbortController());
  // 二维码是否正在刷新
  const [qrcode_load, set_qrcode_load] = useState(false);
  // 扫码token
  const [token, set_token] = useState();
  // token状态：
  // 1：等待扫描；
  // 2：已扫描；null：已过期；
  // 其它：status为access_token的值，代表已经扫码成功
  const [token_status, set_token_status] = useState(-1);
  // 获取生成二维码的token
  const get_token = () => {
    set_qrcode_load(true);
    requests
      .get("/auth/get_qr_login_token")
      .then((res) => {
        set_token_status("1");
        set_token(res.data);
        get_qr_scan_status(res.data);
      })
      .finally(() => {
        set_qrcode_load(false);
      });
  };
  // 获取扫码状态
  const get_qr_scan_status = async (token = "",step = 1) => {
    requests
      .get("/auth/get_qr_login_result", {
        signal: controller.signal,
        params: { token, step },
      })
      .then((res) => {
        const status = res.data + "";
        if (status === "1") {
          get_qr_scan_status(token);
        } else if (status === "2") {
          get_qr_scan_status(token, 2);
        } else if (status !== "None") {
          // 此时status为access_token
          const access_token = status;
          handleSubmit(access_token)
        }
        set_token_status(status);
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          // 请求被取消，这里可以处理取消的情况
          console.log("Request was cancelled.");
        } else {
          // 处理其他错误
          console.error("Fetching data failed:", error);
        }
      });
  };
  useEffect(() => {
    get_token();
    // 清理函数
    return () => {
      // 当组件卸载或依赖项改变时取消请求
      controller.abort();
      // 创建新的AbortController实例以备下次使用
      setController(new AbortController());
    };
  }, [handleSubmit, controller]);
  return (
    <>
      {/* <Button onClick={() => set_qrcode_load(true)}>开启加载</Button>
      <Button onClick={() => set_qrcode_load(false)}>关闭加载</Button>
      <Button onClick={() => set_expired(false)}>显示二维码</Button>
      <Button onClick={() => set_expired(true)}>失效二维码</Button> */}
      <Spin spinning={qrcode_load}>
        {token_status === -1 ? (
          <div
            style={{
              width: 300,
              height: 300,
              margin: "auto",
              backgroundColor: "#f0f0f0" /* 半透明的灰色背景 */,
            }}
          ></div>
        ) : null}
        {token_status === "1" ? (
          <div style={{ textAlign: "center" }}>
            <Canvas
              text={`${window.location.protocol}//${window.location.host}/auth/qr_login_confirm?token=${token}`}
              options={{
                type: "image/jpeg",
                quality: 0.7,
                errorCorrectionLevel: "M",
                margin: 1,
                scale: 2,
                width: 300,
                color: {
                  dark: "#000000ff",
                  light: "#ffffffff",
                },
              }}
            />
            <div style={{ fontSize: "20px" }}>
              请使用
              <span style={{ color: "rgba(var(--semi-blue-5), 1)" }}>
                手机钉钉
              </span>
              扫码登录
            </div>
          </div>
        ) : null}
        {token_status === "2" ? (
          <div
            style={{
              width: 300,
              height: 300,
              margin: "auto",
              textAlign: "center",
              backgroundColor: "#f0f0f0" /* 半透明的灰色背景 */,
            }}
          >
            <Button
              style={{ marginTop: "40%" }}
              icon={<IconPhone />}
              iconPosition="left"
              size="large"
            >
              请在手机上确认登录
            </Button>
          </div>
        ) : null}
        {token_status === "None" ? (
          <div
            style={{
              width: 300,
              height: 300,
              margin: "auto",
              textAlign: "center",
              backgroundColor: "#f0f0f0" /* 半透明的灰色背景 */,
            }}
          >
            <div>
              <Button
                icon={<IconRefresh spin={qrcode_load} />}
                iconPosition="right"
                type="warning"
                style={{ marginTop: "40%" }}
                onClick={get_token}
              >
                二维码失效，点击刷新
              </Button>
            </div>
          </div>
        ) : null}
        <div></div>
      </Spin>
    </>
  );
}
