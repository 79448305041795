import React, { useEffect, useRef } from "react";
import { IconSpin } from "@douyinfe/semi-icons";

const InfiniteScroll = ({ children, loadMore, hasMore, loading }) => {
  const observer = useRef();
  const lastItemRef = useRef();

  useEffect(() => {
    const currentObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && !loading) {
        loadMore();
      }
    });

    if (lastItemRef.current) {
      currentObserver.observe(lastItemRef.current);
    }

    observer.current = currentObserver;

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [hasMore, loading, loadMore]);

  return (
    <div>
      {children}
      <div ref={lastItemRef} style={{ height: "1px" }} />
      {loading && <center><IconSpin spin /></center>}
      {!hasMore && <center>已加载全部数据</center>}
    </div>
  );
};

export default InfiniteScroll;
