import { Button, Modal, Toast } from "@douyinfe/semi-ui";


export default function DelButton({onOk= () => Toast.success("删除成功"), desc="删除", size = "default"}) {

    const handleClick = () => {
        Modal.warning({
            title: '是否要删除数据?',
            onOk: onOk
        })
    }
    return <>
        <Button size={size} type="danger" onClick={handleClick}>{desc}</Button>
    </>
}