import requests from "./utils/request_noloading";
import { get_data, set_data } from "./utils/data_ctl";

// 加载用户信息
const load_user_info = (onSuccess = () => {}, onFail = () => {}) => {
  const user = get_data("user");
  // 用户没登录
  if (!user) {
    onFail();
    return
  }
  // 检测是否绑定工号
  // throttle(() => {
  //   requests.get("/skyy/user/get_self_gonghao").then(res => {
  //     if(!res.data || res.data === "") {
  //       Modal.info({title: "检测到你还未绑定工号", content: <GonghaoBind />})
  //     }

  //   })
  // }, 2000)()

  // 获取用户菜单
  requests
    .get("/skyy/admin/get_user_menus")
    .then((res) => {
      set_data("user_menus", res.data, true);
      const permission_paths = {};
      res.data.forEach((val) => {
        if (val.path != null) {
          permission_paths[val.path] = 1;
        }
      });
      set_data("permission_paths", permission_paths, true);
    })
    .finally(() => {
      // 加载数据完成
      onSuccess()
    });
};

export {load_user_info}
