import { DatePicker, Layout, Button, Table } from "@douyinfe/semi-ui";
import MyButton from "../../../pages/MyButton";
import React, { useState } from "react";
import exportToExcel from "../../../utils/export_exc";
import request from "../../../utils/request";
import "../../../css/a.css";

//列名
const columns = [
  { title: "科室", dataIndex: "dept", width: 180 },
  { dataIndex: "wg", title: "非计划性胃肠管拔管人数" },
  { dataIndex: "qgcg", title: "非计划性气管插管拔管人数" },
  { dataIndex: "zxjmdg", title: "非计划性中心静脉导管拔管人数" },
  { dataIndex: "ylg", title: "非计划性引流管拔管人数" },
  { dataIndex: "ng", title: "非计划性导尿管拔管人数" },
  { dataIndex: "fjhxbgrs", title: "非计划性拔管人数" },
  { dataIndex: "lzwcg", title: "留置胃肠管人数" },
  { dataIndex: "lzqgcg", title: "留置气管插管人数" },
  { dataIndex: "lzzxjmdg", title: "留置中心静脉导管人数" },
  { dataIndex: "lzylg", title: "留置引流管人数" },
  { dataIndex: "lzng", title: "留置导尿管人数" },
  { dataIndex: "lzdgrs", title: "留置导管人数" },
];

//筛选条件
let start_time = null;
let end_time = null;
export default function MgzbBgHLz() {
  //页面布局
  const { Header, Content } = Layout;

  //当前页数据
  const [das, setdas] = useState([]);

  //导出excel数据
  const downloadexcel = async () => {
    exportToExcel(columns, das, "敏感指标数据统计");
  };
  //获取当页数据
  const getdtats = () => {
    getDatas({
      currentPage: -1, //页码
      pageSize: 10, //尺寸
      start_time: start_time, //开始时间
      end_time: end_time, //结束时间
    });
  };
  //发送请求-查询数据
  const getDatas = async (props) => {
    await request.post("/hulibu/mgzb_data", props).then((res) => {
      // 按 dept 属性分组
      const groupedData = res.data.result.reduce((acc, curr) => {
        const dept = curr.dept;
        if (!acc[dept]) {
          acc[dept] = [];
        }
        acc[dept].push(curr);
        return acc;
      }, {});

      // 对每个分组下的每个字段求和，排除 regdate
      let summedData = Object.keys(groupedData).map((dept) => {
        const group = groupedData[dept];
        const summedGroup = group.reduce((acc, curr) => {
          Object.keys(curr).forEach((key) => {
            if (
              key !== "regdate" &&
              key !== "submit_time" &&
              key !== "dept" &&
              key !== "hlzlkzmgzbsbb_id"
            ) {
              acc[key] = (acc[key] || 0) + parseFloat(curr[key]);
            } else {
              acc[key] = curr[key]; // 保留非数值属性
            }
          });
          return acc;
        }, {});
        summedGroup.dept = dept;
        return summedGroup;
      });

      // 计算留置导管人数，以解决数据库中保存的数据为NaN的问题
      summedData = summedData.map((val) => {
        val.lzdgrs =
          (val.lzwcg ? val.lzwcg : 0) +
          (val.lzqgcg ? val.lzqgcg : 0) +
          (val.lzzxjmdg ? val.lzzxjmdg : 0) +
          (val.lzylg ? val.lzylg : 0) +
          (val.lzng ? val.lzng : 0);
        return val;
      });

      // 计算总合计
      const totalSum = summedData.reduce(
        (acc, curr) => {
          Object.keys(curr).forEach((key) => {
            if (
              key !== "dept" &&
              key !== "regdate" &&
              key !== "submit_time" &&
              key !== "hlzlkzmgzbsbb_id"
            ) {
              acc[key] = (acc[key] || 0) + parseFloat(curr[key]);
            }
          });
          return acc;
        },
        { dept: "合计" }
      );

      // console.log(summedData);

      summedData.push(totalSum);
      setdas(summedData);
    });
  };

  //设置时间范围
  const SelectTime = (date, dateString) => {
    start_time = dateString[0];
    end_time = dateString[1];
  };

  return (
    <>
      <Layout className="components-layout-demo">
        <Header>
          <br></br>
          &nbsp;&nbsp;&nbsp;&nbsp;上报日期：
          <DatePicker
            type="dateRange"
            density="compact"
            style={{ width: 260 }}
            onChange={SelectTime}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <MyButton name="查询" clicfun={getdtats} />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <MyButton name="导出 EXCEL" clicfun={downloadexcel} />
        </Header>

        <Content>
          <br></br>
          <Table
            columns={columns}
            dataSource={das}
            pagination={false}
            bordered={true}
            size={"middle"}
            sticky={true}
          />
        </Content>
      </Layout>
    </>
  );
}
