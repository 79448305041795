import { createRoot } from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routers from "./router";
import doWithAuthCode from "./utils/dd_api";
import { set_data } from "./utils/data_ctl";
import { Modal } from "@douyinfe/semi-ui";
import GonghaoBind from "./component/gonghao_bind";
import { load_user_info } from "./sys_init";

// 本文件所有代码在页面加载时执行

// 钉钉调试模式
// import { initDingH5RemoteDebug } from "dingtalk-h5-remote-debug";
// initDingH5RemoteDebug();

// 初始化路由
const router = createBrowserRouter(routers);
export { router };

const root = createRoot(document.getElementById("root"));

let inDing = true;

// 解决开发环境报错的问题
// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
//    constructor(callback) {
//      callback = debounce(callback, 200);
//      super(callback);
//    }
// }

doWithAuthCode(
  () => {
    set_data("inDing", inDing);
  },
  (err) => {
    inDing = false;
    set_data("inDing", inDing);
  },
  () => {
    load_user_info(
      () => {
        // 加载成功
        root.render(<RouterProvider router={router} />);
      },
      () => {
        // 加载失败
        root.render(<RouterProvider router={router} />);
      }
    );
    // 检测是否绑定工号
    // throttle(() => {
    //   requests.get("/skyy/user/get_self_gonghao").then(res => {
    //     if(!res.data || res.data === "") {
    //       Modal.info({title: "检测到你还未绑定工号", content: <GonghaoBind />})
    //     }

    //   })
    // }, 2000)()
  }
);
