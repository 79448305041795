import React, { useEffect, useState } from "react";
import { Toast, Card, RadioGroup, Radio, Banner, Notification } from '@douyinfe/semi-ui';
import { useNavigate } from "react-router-dom";
import { set_data, remove_data, get_data } from '../../../../utils/data_ctl';
import './login.css'
import doWithAuthCode from "../../../../utils/dd_api";
import requests from "../../../../utils/request";
import PwdLogin from "../pages/PwdLogin";
import DingLogin from "../pages/DingLogin";
import QRCodeLogin from "../pages/QRCodeLogin";
import { convertUrlParamsToJson } from "../../../../utils/location_util";
import { change_route } from "../../../../router";
import QRCodeLogin2 from "../pages/QRCodeLogin2";

// 登录成功后的默认跳转路径
const default_url = '/'

// 保存用户登录信息的cookie过期时间3小时
const user_login_exp = 1000 * 60 * 60 * 3
// const user_login_exp = 1000 * 5

class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
            remember: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onRememberChange = this.onRememberChange.bind(this);
    }

    componentDidMount() {
        remove_data("user")
    }

    onChange(value, e) {
        if (e.target.id === "-1") {
            this.setState({ "username": value });
        } else if (e.target.id === "-2") {
            this.setState({ "password": value });
        } else if (e.target.id === "-3") {
            this.setState({ "remember": value });
        }
    }
    onRememberChange(e) {
        this.setState({ "remember": e.target.checked })
    }
    render() {
        return (
            <>
                <LogMain pt={this} />
            </>
        );
    }
}

export default Login;

function LogMain({ pt }) {
    const inDing = get_data('inDing')
    const navigate = useNavigate()
    const [logType, setLogType] = useState(inDing ? 2 : 3)
    const [tipData, setTipData] = useState({visible: false, content: ""})
    let redirect_url = convertUrlParamsToJson(window.location.search)['redirect']
    redirect_url = redirect_url ? decodeURIComponent(redirect_url) : default_url
    // 账号密码登录
    const handleSubmit1 = async () => {
        if (!pt.state.username || !pt.state.password) {
            Toast.warning({ content: '账号或密码不能为空！', theme: 'light', duration: 2 })
            return
        }
        const info = {
            username: pt.state.username,
            password: pt.state.password,
            remember: pt.state.remember
        }
        let result = false
        const res = await requests.post("/auth/login", info)
        if (res.code === 200) {
            result = true
        } else {
            result = false
        }
        if (result) await handle_login_success(res.data, navigate, redirect_url)
    };
    // 钉钉登录
    const handleSubmit2 = async (loading, setLoading, setFailObj) => {
        if (!loading) {
            setLoading(true)
            doWithAuthCode(code => {
                requests.get("/auth/dd_login", {
                    params: { code, type: 'code' }
                }).then(res => {
                    handle_login_success(res.data, navigate, redirect_url)
                }).finally(() => {
                    setLoading(false)
                })
            }, err => {
                setLoading(false)
                setFailObj({ 'text': err.message, failed: true })
            })
        }
    }
    // 特定小程序登录提示
    useEffect(() => {
        if (redirect_url === "/skyy/exam/exam_index" && inDing) {
            setTipData({visible: true, content: "各位考生请点击下方按钮登录后参加考试"})
        } else if(redirect_url === "/hlb/mgzb_qt" && inDing) {
            setTipData({visible: true, content: "请点击下方按钮登录后上报数据"})
        }
    }, [])


    // 钉钉扫码登录
    const handleSubmit3 = async (code) => {
        requests.get("/auth/dd_login", {
            params: { code, type: 'sns' }
        }).then(res => {
            handle_login_success(res.data, navigate, redirect_url)
        })
    }

    // 自研扫码登录
    const handleSubmit4 = async (code) => {
        requests.get("/auth/dd_login", {
            params: { code, type: 'code' }
        }).then(res => {
            handle_login_success(res.data, navigate, redirect_url)
        })
    }
    return <>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Card
                style={{ padding: '15px', height: '100vh' }}
            >
                <img src="/hospital.png" alt="" height="100" width="100%"></img>
                <br />
                {tipData.visible ? <Banner
                    type="info"
                    description={tipData.content}
                    closeIcon={null}
                /> : null}
                <br />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <RadioGroup type='button' buttonSize='large' value={logType} aria-label="登录方式" name="demo-radio-large" onChange={e => setLogType(e.target.value)}>
                        {inDing ? (<Radio value={2}>钉钉登录</Radio>) : (<Radio value={3}>扫码登录</Radio>)}
                        <Radio value={1}>账号密码</Radio>
                    </RadioGroup>
                </div>
                <br />
                {(
                    () => {
                        switch (logType) {
                            case 1:
                                return <PwdLogin pt={pt} handleSubmit={handleSubmit1} />
                            case 2:
                                return <DingLogin handleSubmit={handleSubmit2} />
                            case 3:
                                // return <QRCodeLogin handleSubmit={handleSubmit3} />
                                return <QRCodeLogin2 handleSubmit={handleSubmit4} />
                            default:
                                return <PwdLogin pt={pt} handleSubmit={handleSubmit1} />
                        }
                    }
                )()}

            </Card>
        </div>
    </>
}





async function handle_login_success(user, navigate, url) {
    set_data("user", user, false, user_login_exp)
    // Notification.success({"title": "登录成功", "content": `欢迎你, ${user.username}`, "duration": 2.5, position: 'bottom'})
    // window.location.href = url
    change_route(url, true)
    // navigate(url)
}
