import { useEffect } from "react";
import { to_login } from "../utils/location_util";

export default function ToLogin() {
  useEffect(() => {
    to_login();
  }, []);
  return (
    <>
      <center>正在返回登录页...</center>
    </>
  );
}
