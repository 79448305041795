import { React, useEffect, useState, useRef } from "react";
import {
  Button,
  Space,
  Steps,
  Avatar,
  Card,
  Spin,
  Badge,
  Tag,
  Typography,
} from "@douyinfe/semi-ui";
import { IconHourglass } from "@douyinfe/semi-icons";
import { convertUrlParamsToJson } from "../../../../utils/location_util";
import requests from "../../../../utils/request_noloading";
import { closeWindow } from "../../../../utils/dd_api";
import { useNavigate } from "react-router";
import moment from "moment";
import CondShowComp from "../../../../component/cond_show_comp";

const fake_data = [
  { username: "44", is_look: false, order_num: 4, bz: "第四顺序" },
  { username: "33", is_look: false, order_num: 3, bz: "第三顺序" },
  { username: "22", is_look: true, order_num: 2, bz: "第二顺序" },
  { username: "222", is_look: true, order_num: 2, bz: "第二顺序" },
  { username: "222", is_look: true, order_num: 2, bz: "第二顺序" },
  { username: "222", is_look: true, order_num: 2, bz: "第二顺序" },
  { username: "222", is_look: true, order_num: 2, bz: "第二顺序" },
  {
    username: "11",
    is_look: true,
    order_num: 1,
    bz: "第一顺序",
    ldps: "duawhudhnaiuwd",
  },
];

export default function YuewenLiuchengShow({
  sw_id,
  yl_id = null,
  refresh_flag = false,
}) {
  // const sw_id = convertUrlParamsToJson(window.location.search)["sw_id"];
  const [data, set_data] = useState([]);
  const [cur_finish_order, set_cur_finish_order] = useState(0);
  const [load, set_load] = useState(false);
  useEffect(() => {
    set_load(true);
    requests
      .get("/office/get_shouwen_all_liucheng", {
        params: { sw_id },
      })
      .then((res) => {
        // 后端返回数据为降序，需要处理
        if (res.data) {
          const d = res.data;
          // const d = fake_data;
          let need_data = [];
          const len = d.length;
          const order_map = {};
          // 初始化数组
          for (let i = 0; i < len; i++) {
            if (!order_map[d[i].order_num]) {
              need_data.push([]);
              order_map[d[i].order_num] = true;
            }
          }
          // 将数据根据order_num存入数组中
          let cur_order = len;
          let flag = true;
          for (let i = len - 1; i >= 0; i--) {
            const liucheng = d[i];
            if (flag && !liucheng.is_look) {
              cur_order = liucheng.order_num - 1;
              flag = false;
            }
            need_data[liucheng.order_num - 1].push(liucheng);
          }
          set_cur_finish_order(cur_order);
          set_data(need_data);
        }
      })
      .finally(() => {
        set_load(false);
      });
  }, [refresh_flag]);
  return (
    <div>
      <Spin spinning={load} tip="加载中...">
        <Steps
          direction="vertical"
          type="basic"
          current={cur_finish_order}
          size="small"
          // onChange={(i) => console.log(i)}
        >
          {data.map((userlist, idx) => {
            // 是否有用户未读
            let have_unlook = false;
            for (let v of userlist) {
              if (v.look_time == null) {
                have_unlook = true;
                break;
              }
            }
            let minValue = false;
            // 有用户未读则不显示处理时间
            if (!have_unlook) {
              // 获取第一个处理人的处理时间
              minValue = userlist.reduce(
                (min, obj) => (obj.look_time < min ? obj.look_time : min),
                userlist[0].look_time
              );
            }
            return (
              <Steps.Step
                key={idx}
                // `${userlist[0].bz}_处理时间: ${moment(maxValue).format("YYYY-MM-DD HH:mm")}`
                title={
                  <div>
                    <div>
                      <h3
                        style={{
                          wordWrap: "break-word",
                          margin: 1,
                          whiteSpace: "normal",
                        }}
                      >
                        {userlist[0].bz}
                      </h3>
                    </div>
                    <div style={{ margin: 1 }}>
                      <CondShowComp
                        cond_func={() => minValue}
                        comp={
                          <Tag size="small" color="white" style={{color: 'grey'}}>
                            <strong>{`处理时间: ${moment(minValue).format(
                              "YYYY-MM-DD HH:mm"
                            )}`}</strong>
                            
                          </Tag>
                        }
                      />
                    </div>
                  </div>
                }
                description={<Detail user_list={userlist} yl_id={yl_id} />}
              />
            );
          })}
        </Steps>
      </Spin>
    </div>
  );
}

function Detail({ user_list, yl_id }) {
  return (
    <div style={{ marginTop: 20 }}>
      <Space spacing={25} wrap>
        {user_list.map((user, idx) => (
          <div key={idx}>
            <div>
              <div>
                <Badge
                  count={user.is_look ? "✔" : <IconHourglass />}
                  position="rightTop"
                  type={user.is_look ? "success" : "tertiary"}
                >
                  {user.yueshi_liucheng_id !== yl_id ? (
                    <Avatar
                      color="light-blue"
                      shape="square"
                      size="default"
                      gap={0}
                    >
                      {user.username}
                    </Avatar>
                  ) : (
                    <Avatar
                      color="light-blue"
                      shape="square"
                      size="default"
                      border={{ motion: true }}
                      gap={0}
                    >
                      {user.username}
                    </Avatar>
                  )}
                </Badge>
              </div>
              <div>
                {user.ldps ? (
                  <Tag> {user.ldps} </Tag>
                ) : (
                  <Tag style={{ visibility: "hidden" }}> {user.ldps} </Tag>
                )}
              </div>
            </div>
          </div>
        ))}
      </Space>
    </div>
  );
}
