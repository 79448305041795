import React, { useState, useEffect } from 'react';
import { Table, Button, Toast, Input as XInput } from '@douyinfe/semi-ui';
import requests from '../../../../utils/request_noloading'
import AddXxkAccount from '../pages/add_xxk_account';
import DelButton from '../../../../component/del_button';
import moment from 'moment'
import EditXxkAccount from '../pages/edit_xxk_account';
import { debounce } from '../../../../utils/user_friendly';

let set_cur_data_copy = null

let set_edit_v_copy = null

let getdas = null

let setDas = null

const operation = (text, record) => {


    const handleDel = () => {
        requests.get("/skyy/admin/del_tp_client", {
            params: { tp_client_id: record.tp_client_id }
        }).then(res => {
            Toast.success("删除成功")
            let key = record.tp_client_id
            const das = getdas()
            let newDataSource = [...das];
            if (key != null) {
                let idx = newDataSource.findIndex(data => data.tp_client_id === key);
                if (idx > -1) {
                    newDataSource.splice(idx, 1);
                    setDas(newDataSource);
                }
            }
        })
    }

    const handleEdit = () => {
        set_edit_v_copy(true)
        set_cur_data_copy(record)
    }
    return <>
        <Button onClick={handleEdit}>详情/编辑</Button>
        {/* <DelButton onOk={handleDel} desc='删除' /> */}
    </>
}

const columns = [
    {
        title: '资源名称',
        dataIndex: 'name',
    },
    {
        title: 'IP地址',
        dataIndex: 'ip',
    },
    {
        title: '创建时间',
        dataIndex: 'create_time',
        render: value => {
            return moment(value).format('YYYY-MM-DD HH:mm:ss');
        }
    },
    {
        title: '最后修改时间',
        dataIndex: 'update_time',
        render: value => {
            return moment(value).format('YYYY-MM-DD HH:mm:ss');
        }
    },
    {
        title: '操作',
        render: operation
    }
]

export default function AccountList() {
    const [dataSource, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [add_v, set_add_v] = useState(false)
    const [edit_v, set_edit_v] = useState(false)
    const [cur_data, set_cur_data] = useState(null)
    const [keyword, set_keyword] = useState(null)

    set_edit_v_copy = set_edit_v

    set_cur_data_copy = set_cur_data

    const fetchData = (cur = currentPage, size = pageSize, key = keyword) => {
        setLoading(true);
        requests.post("/xxk/account/get_account_list", { currentPage: cur, pageSize: size, key: key }).then(res => {
            setData(res.data.result)
            setTotal(res.data.total)
        }).finally(setLoading(false))
    };

    const handleChange = (cur, size) => {
        setPage(cur)
        setPageSize(size)
    };

    useEffect(() => {
        fetchData(currentPage, pageSize)
    }, [currentPage, pageSize, keyword])

    const handleAdd = () => {
        set_add_v(true)
    }

    const handleKeyChange = (val) => {
        if(val != null) {
            setPage(1)
            set_keyword(val)
        }
    }

    return <div style={{width: '100%'}}>
        <div style={{ width: '100%', marginBottom: 10, marginTop: 10 }}>
            <XInput style={{width: "50%"}} placeholder={"输入关键字搜索"} onChange={debounce(handleKeyChange, 300)}/>
            <Button style={{float: 'right'}} onClick={handleAdd} theme='solid'>新增账号</Button>
        </div>
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{
                currentPage,
                pageSize: pageSize,
                total: total,
                onChange: handleChange,
                showSizeChanger: true,
                pageSizeOpts: [20, 50, 100, 500, 700]
            }}
            loading={loading}
            bordered={true}
            sticky={true}
        />
        <AddXxkAccount visible={add_v} setVisible={set_add_v} fetchData={fetchData}/>
        <EditXxkAccount cur_data={cur_data} visible={edit_v} setVisible={set_edit_v} fetchData={fetchData}/>
    </div>
}