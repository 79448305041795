import { Row, Button, Col } from "@douyinfe/semi-ui";
import { IconTickCircle } from "@douyinfe/semi-icons";
import { closeWindow } from "../../../../utils/dd_api";

export default function ShouwenYueshiSuccess() {

  const onClose = () => {
    closeWindow()
  }
  return (
    <>
      <div style={{textAlign: 'center', marginTop: '30%'}}>
        <IconTickCircle style={{fontSize: 50, color: "var(--semi-color-success)"}} />
        <h2>操作成功</h2>
        <Button onClick={onClose}>关闭</Button>
      </div>
    </>
  );
}
