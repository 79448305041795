import { Toast } from '@douyinfe/semi-ui';
import * as dd from 'dingtalk-jsapi';


export default async function doWithAuthCode(cb = (code) => { }, errcb = (err) => Toast.error(err.message), cpecb = () => { }) {
    dd.getAuthCode({
        corpId: 'dingb915c246d3776ce7f5bf40eda33b7ba0',
        success: (res) => {
            const { code } = res;
            cb(code)

        },
        fail: (err) => {
            Toast.error("获取数据失败: " + JSON.stringify(err))
        },
        complete: (res) => {

        },
    }).catch(err => {
        errcb(err)
    }).finally(() => {
        cpecb()
    })

}

function doWithScan(cb = (text) => { }) {
    dd.scan({
        type: 'qr',
        success: (res) => {
            const { text } = res;
            cb(text)
        },
        fail: () => { },
        complete: () => { },
    });
}

function closeWindow() {
    dd.biz.navigation.close()
}

export { doWithScan, closeWindow }