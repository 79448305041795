import { get_data } from "../utils/data_ctl";
import requests from "../utils/request_noloading";
import ToLogin from "./to_login_page";

// const permission_paths = {
//     "": 1,
//     "/": 1,
//     "about": 1,
//     "/skyy/admin/user_manage": 1,
//     "/skyy/admin/user_group_manage": 1,
//     "/skyy/admin/menu_manage": 1
// }

let timer;

// 封装日志记录函数, 防止短时间内多次调用
function save_log(path, query, delay) {
  if (timer) {
    return;
  }
  timer = setTimeout(function () {
    requests.post("/skyy/xlog/access", { path, query });
    timer = null; // 在delay后执行完fn之后清空timer，此时timer为假，throttle触发可以进入计时器
  }, delay);
}

/**
 *
 * result 参数说明
 * 0: 正常
 * 1: 权限不足
 */
export function PermRouteWrapper({ element }) {
  // 检测是否登录
  if (!get_data("user")) {
    return <ToLogin/>;
  }
  const permission_paths = get_data("permission_paths", true);
  // 鉴权
  let result = 0;

  if (!permission_paths[window.location.pathname]) result = 1;
  // 首页放行
  if (window.location.pathname === "/") result = 0;
  switch (result) {
    case 0:
      save_log(window.location.pathname, window.location.search, 1000);
      return element;
    case 1:
      return <NoPermission />;
    default:
      return <h1>未知代码</h1>;
  }
}

export function RouteWrapper({ element }) {
  return element;
}

// export {PermRouteWrapper, RouteWrapper}

function NoPermission() {
  return <h1 style={{ textAlign: "center" }}>权限不足</h1>;
}
