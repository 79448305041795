import { useEffect, useState } from "react";
import { Button, Spin, Typography, Layout } from "@douyinfe/semi-ui";
import { IconRefresh, IconTickCircle, IconPhone } from "@douyinfe/semi-icons";
import requests from "../../../../utils/request_noloading";
import doWithAuthCode, { closeWindow } from "../../../../utils/dd_api";
import { convertUrlParamsToJson } from "../../../../utils/location_util";
import { get_data } from "../../../../utils/data_ctl";

let code = null;

// 自己开发的扫码登录确认页面
export default function QRLoginConfirm() {
  document.title = "安全登录";
  const { Header, Footer, Content } = Layout;
  const [load, set_load] = useState(false);
  // 当前扫码状态
  const [scan_status, set_scan_status] = useState(-1);
  const params = convertUrlParamsToJson(window.location.search);
  const token = params.token;
  const inDing = get_data("inDing");
  const confirm_status = (token, status, loading = false, close = false) => {
    if (loading) set_load(true);
    requests
      .get("/auth/qr_login_confirm", {
        params: { token, status },
      })
      .then((res) => {
        set_scan_status(res.data + "");
        if (close) closeWindow();
      })
      .finally(() => {
        if (loading) set_load(false);
      });
  };

  const on_confirm = () => {
    confirm_status(token, code, true, true);
  };

  const on_cacel = () => {
    confirm_status(token, "None", false, true);
  };

  useEffect(() => {
    if (inDing) {
      confirm_status(token, "2");
      doWithAuthCode((c) => {
        code = c;
      });
    }
  }, []);

  return (
    <Layout className="components-layout-demo">
      <Header
        style={{
          height: 300,
          lineHeight: "64px",
        }}
      ></Header>
      <Content style={{ height: 600, textAlign: "center" }}>
        {inDing ? (
          <>
            {scan_status === "1" ? (
              <div style={{ textAlign: "center" }}>
                <h2>确认登录</h2>
                <Button onClick={on_cacel} style={{ width: "40%" }}>
                  取消
                </Button>
                <Button
                  onClick={on_confirm}
                  style={{ width: "40%" }}
                  theme="solid"
                  loading={load}
                >
                  确认
                </Button>
              </div>
            ) : null}

            {scan_status === "2" ? (
              <>
                <div style={{ textAlign: "center" }}>
                  <h2 style={{ color: "orange" }}>二维码已被扫描</h2>
                  <Button onClick={closeWindow} block>
                    关闭
                  </Button>
                </div>
              </>
            ) : null}

            {scan_status === "None" ? (
              <>
                <div style={{ textAlign: "center" }}>
                  <h4 style={{ color: "red" }}>二维码已过期</h4>
                  <Button onClick={closeWindow} block>
                    关闭
                  </Button>
                </div>
              </>
            ) : null}
          </>
        ) : (
          <h2 style={{ color: "red" }}>请使用手机钉钉扫码</h2>
        )}
      </Content>
      <Footer></Footer>
    </Layout>
  );
}
